/**
 * Helper File, these are generic classes used throughout the site
 */
.clear {
  clear: both;
  display: inline-block;
}

.clear-block {
  clear: both;
  display: block;
}

.padout {
  padding: 5px;
}

.aleft {
  text-align: left;
}

.aright {
  text-align: right;
}

.acenter {
  text-align: center;
}

.bumpdown {
  margin-top: 20px;
}

.smallbold {
  font-size: 0.9em;
  font-weight: bold;
}

.strike,a:link.strike {
  text-decoration: line-through;
}

.bold {
  font-weight: bold;
}

.smaller {
  font-size: 0.85em;
}

.small {
  font-size: 0.9em;
}

/* Border killers */
.bkill-left {
  border-left: none;
}

.bkill-right {
  border-right: none;
}

.bkill-top {
  border-top: none;
}

.bkill-bottom {
  border-bottom: none;
}