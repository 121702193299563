/**
 * V4 Layout
 *
 * This is only designed to work on any page with a class of layout-backend-v4 against the body
 */

html > body.layout-backend-v4 {
  overflow-y: scroll; // Trick to force browsers to always have a scrollbar
}

body.layout-backend-v4 {
  width: 100%;

  // Environmental hints 
  .env-demo {
    background-image: url('/albatross/static/images/environment-hint-demo.png');
  }
  .env-qa {
    background-image: url('/albatross/static/images/environment-hint-qa.png');
  }
  
  
  // Header Stuff
  @import "base/header";

  @import 'base/print';
  
  // Smaller header
  
  
}

// Pages designed to run below the min width of 960px. There's no min
// width set, it's up to the page to handle it
body.smaller-min-width {
  #nav, #footer, #page-content-and-sidebar {
    min-width: 400px!important;
    width: auto!important;
  }
}

body.simple-layout-vue {
  height: 300px;
}