#event-edit-popup {
  width: 650px;
  max-height: 700px;

  .form-field-element {
    display: block;
  }

  .footer-buttons-bar {
    background: none;
  }
}

#events-popup {
  width: 900px !important;
  max-height: 700px;

  .form-field-element {
    display: block;
  }

  .indef-wrapper {
    width: 130px;
  }

  .filter-from, .filter-to {
    width: 120px;
  }

  table {
    width: 900px;
    table-layout: fixed;

    th {
      &.id, &.start-date, &.end-date {
        width: 80px;
      }
      &.name {
        width: 150px;
      }
    }

    td {
      &.truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .footer-buttons-bar {
    background: none;
  }

  .buttons-bar {
    padding-bottom: 5px;

    .bar-left {
      display: inline-block;
      float: left;
    }
  }
}

.colpick {
  z-index: 9999;
}