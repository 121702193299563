@import 'booking-emails';

$headBackgroundColor: #b2c74e;
$headBorderColor: #A4AD74;
$headPartialBackgroundColor: rgba(0, 0, 0, 0.04);

$evenBackgroundColor: #ECF5D1;
$evenBorderColor: darken($evenBackgroundColor, 15%);
$evenPartialBackgroundColor: rgba(0, 0, 0, 0.04);

$oddBackgroundColor: #ffffff;
$oddBorderColor: darken($oddBackgroundColor, 15%);
$oddPartialBackgroundColor: rgba(0, 0, 0, 0.04);

$backgroundColor: #fafafa;
$borderColor: darken($backgroundColor, 10%);
$partialBackgroundColor: rgba(0, 0, 0, 0.05);

.component-automation {

  .info {
    width: auto;
    font-style: italic;
    margin-bottom: 20px;
  }

  .footer-buttons-bar {
    margin-top: 30px;
  }

  &.component-booking-rules {
    padding: 0 1em;
  }

  .component-rules-manager {
    // Keep things contained 
    position: relative;
    min-height: 200px;

    //margin: 0 auto;
    //max-width: 800px;

    .top-actions-bar {
      position: relative;
      margin-top: 8px;

      .left {
        display: inline-block;

        p {
          font-style: italic;
        }
      }

      .right {
        display: inline-block;
        text-align: right;
        float: right;
      }

      &:after {
        content: ' ';
        clear: both;
        display: block;
      }
    }

    .contained-rules {
      margin: 5px;

      .rule-row-wrapper:last-child {
        .odd {
          .component-rule {
            &.collapsed {
              border-bottom: 1px solid $oddBorderColor;
            }
          }
        }

        .even {
          .component-rule {
            &.collapsed {
              border-bottom: 1px solid $evenBorderColor;
            }
          }
        }
      }
    }

    .rules-and-header {
      margin: 5px;

      .rules-header, .rules-row-wrapper {
        display: block;
        box-sizing: content-box;
      }

      .rules-header {
        background: $headBackgroundColor;
        border: 1px solid $headBorderColor;
        padding: 2px 5px;
        font-weight: bold;

        > span, > a {
          display: inline-block;
          vertical-align: middle;
          padding: 0 10px 0 2px;
        }

        .rule-enabled {
          float: right;
        }
      }
    }

    .rule-row-wrapper {
      padding: 0 !important;

      select {
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) inset;
        -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) inset;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) inset;

        padding: 2px 0 2px 4px;
        text-align: left;
        min-width: 140px !important;
        width: auto !important;
        position: relative;

      }

      // Colours for the rules
      .odd {
        .component-rule {
          &.collapsed {
            background: $oddBackgroundColor;
            border-color: $oddBorderColor;

            //&:hover {
            //  background: darken($oddBackgroundColor, 5%);
            //  border-color: darken($oddBorderColor, 5%);
            //}
          }
        }
      }

      .even {
        .component-rule {
          &.collapsed {
            background: $evenBackgroundColor;
            border-color: $evenBorderColor;

            //&:hover {
            //  background: darken($evenBackgroundColor, 5%);
            //  border-color: darken($evenBorderColor, 5%);
            //}
          }
        }
      }

      .component-rule {
        position: relative;
        //background: $backgroundColor;
        //border: 1px solid $borderColor;

        // Handle the loader
        .sk-loader {
          //z-index: 100; // Make sure it places above things
        }

        &.collapsed {
          background: $backgroundColor;
          border: 1px solid $borderColor;
          border-top: none;

          padding: 5px;

          > span, > a {
            display: inline-block;
            vertical-align: middle;
            padding: 0 10px 0 2px;

            &.rule-title {
              width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .rule-id {
            font-weight: bold;
            min-width: 30px;
          }

          .rule-enabled {
            float: right;
            text-align: right;
          }

          &:after {
            clear: both;
            display: block;
            content: ' ';
          }
        }

        h3 {
          padding: 0;
          font-size: 1.4em;
        }

        ul.component-box {
          display: block;
          list-style-type: none;
          padding: 0;
          //margin: 0;
          margin: 0 0 5px 0;
          //border: 1px dotted lighten($borderColor, 0%);
          border-bottom: 1px solid darken($borderColor, 5%);
          padding-bottom: 10px;
          //border-left: 1px solid $borderColor;
          //border-right: 1px solid $borderColor;
          //background: lighten($evenBackgroundColor, 6%);
          //border-color: transparent;

          &.actions {
            border-bottom: none;
          }

          &.description {
            border-top-width: 1px;
          }

          &.actions {
            border-bottom-width: 1px;
          }

          &:not(.actions) {
            //border-bottom: 1px dotted $borderColor;
          }

          & > li:not(.component-toptitle):not(.component-li) {
            //border: 1px solid #000;
            margin: 10px
          }

          li.component-toptitle {
            padding: 10px;
          }

          //  WHEN, AND, DO ACTION
          .component-sublabel {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 1.25em;
          }

          .component-description {
            font-style: italic;
            display: inline-block;
            vertical-align: tex;
          }

          li.title-spacer {
            display: block;
            height: 1px;
            overflow: hidden;
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
            margin: 0;
            clear: both;
          }

          // Description Lists
          &.description {
            ul {
              display: inline-block;
              list-style-type: none;
              vertical-align: top;
              margin-right: 40px;
              padding: 0;

              .description-label {
                font-weight: bold;
              }
            }

          }

          // Trigger
          &.trigger {
            ul {
              list-style-type: none;
              padding: 0;
            }
          }

          .component-toptitle {
            display: block;
          }

          .component-li {
            margin: 8px 10px;
          }

          .component-add {
            float: right;
          }

          li.subcomponent-group {
            display: block !important;
            > ul {
              margin: 0;
              padding: 0;
              //column-width: 500px;

              > li {
                position: relative;
                -webkit-column-break-inside: avoid; /* Chrome, Safari */
                page-break-inside: avoid; /* Theoretically FF 20+ */
                break-inside: avoid-column; /* IE 11 */
                //margin: 0;
              }

            }
          }

          // Conditions & Actions
          > li:not(.component-add):not(.component-toptitle), .subcomponent-group > ul > li {

            display: inline-block;
            vertical-align: top;

            &.component-li {
              display: block;
            }

            .component-header-row {
              display: block;

              .component-title {
                //display: block;
                color: #9F0000;
                margin-right: 10px;
                cursor: pointer;
                min-width: 200px;
                padding-left: 5px;
              }

              .component-expand {
                cursor: pointer;
                color: #9F0000;
              }

              .component-description {
                cursor: pointer;
              }

              .component-remove {
                float: right;
              }

              &:after {
                clear: both;
                display: block;
                content: '';
              }
            }

            > div {
              border: 1px solid $evenBorderColor;
              background: $evenBackgroundColor;

              &:hover {
                border-color: darken($evenBorderColor, 10%);
                background: darken($evenBackgroundColor, 10%);
              }

              ul {
                margin: 0 10px 0 10px;
                padding: 0;
                list-style-type: none;

                li {
                  padding: 4px 0;
                }
              }

              .component-item {
                margin-left: 10px;
                display: inline-block;
                margin-right: 10px;

                .description {
                  font-style: italic;
                  font-size: .9em;
                }
              }
            }
          }

          .component-title {
            font-weight: bold;
            text-transform: uppercase;
            display: inline-block;
          }

          .component-item {

          }
        }

        .component-actions-bar {
          margin-top: 20px;
          padding: 5px;
          background: $partialBackgroundColor;
          border: 1px solid $borderColor;

          .left {
            display: inline-block;

            p {
              font-style: italic;
            }
          }

          .right {
            display: inline-block;
            text-align: right;
            float: right;
          }

          &:after {
            content: ' ';
            clear: both;
            display: block;
          }
        }
      }
    }

  }

}

.component-emails-legacy-rules {
  .legacy-email-rules {
    position: relative;
    min-height: 200px;
    //max-width: 1000px;
    margin: 0 auto;
    padding: 0 10px;

    .vertical-list {
      margin-top: 20px;
      display: block;

      table {
        width: auto;

        thead {
          th {
            text-align: center;
          }
        }

        tbody {
          th {
            width: 150px;
          }

          td {
            width: 100px;
            text-align: center;
            div {
              margin: 0 auto;
            }
          }
        }
      }

      .global-rules {
        margin-bottom: 10px;
        .global-rule {
          //width: 200px;
          display: inline-block;
          margin: 0 10px 20px 10px;

          > label {
            font-weight: bold;
            padding: 4px;
            //display: inline-block;
          }

          .sk-toggle {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .quick-toggles {
        font-weight: bold;
        margin-top: 20px;
      }

      .list-items {
        column-width: 260px;

        .list-item {
          display: block;
          margin: 0 auto;
          vertical-align: top;
          padding: 0 4px 4px 0;
          box-sizing: border-box;

          .item {
            label span {
              width: 210px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

          }
        }
      }
    }
  }
}

/** Logs View **/
.component-logs-view {
  position: relative;

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  table {
    font-size: .95em;
  }

  tr {
    td {
      vertical-align: top;
    }
  }

  .sub-actions, .sub-actions-header {
    font-style: italic;
    font-size: .95em;
  }

  .sub-actions-header {
    width: 150px;
    text-align: right;
  }

}


.component-automation-booking-emails {
  //position: relative;
  //min-height: 200px;
  padding-top: 20px;
}

.tabbed-automation-content {
  position: relative;
  top: -5px;
  display: block;
}

/** Override Rule Manager */
.component-automation-override-manager {
  // Keep things contained
  position: relative;
  min-height: 200px;
  //padding-top: 20px;

  .message-box {
    max-width: 400px;
    margin: 0 auto;
  }

  tr.component-automation-override-manager-row {
    td.rule-title {
      span.rule-title {
        font-weight: bold;
      }
    }

    td.rule-description {
      font-style: italic;
    }

    td.rule-enabled {
      text-align: right;
    }

    td.rule-components {
      vertical-align: top;
      //float: right;

      ul {
        display: inline-block;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          margin-right: 10px;
        }
      }

      .description {
        font-style: italic;
        font-size: .95em;
      }

      .input-title, .input-label {
        display: none;
      }

    }
  }

}

.input-component {
  display: inline-block;
  label {
    font-weight: bold;
  }
}

// Specifics for the booking emails
.component-automation-booking-emails .component-automation-override-manager tr.component-automation-override-manager-row {
  td.rule-components {
    .description {
      display: none;
    }
  }
}
