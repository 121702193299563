/**
 * iBex Backend Header
 */

$headerBackColor: #1A1919;
$headerTextColor: #888888;
$headerLinkColor: #B6CF67;
$headerWrapperBack: #333333;

#top {
  background: $headerBackColor;
  color: $headerTextColor;
  font-size: 11px;
  padding: 0 1em;
  line-height: 22px;

  .is-master-login {
    width: 33%;
  }

  #datetime {
    float: left;

    span {
      margin-right: 11px;
    }
  }

  #login-info {
    float: right;

    span {
      margin-left: 11px;
    }
  }

  a {
    color: $headerLinkColor;
    text-decoration: none;
  }
}

#nav-wrapper {
  height: 55px;
  background: $headerWrapperBack;

  #nav {
    width: 960px;
    margin: auto;

    #side-nav {
      float: left;

      #site-logo {
        display: inline-block;
        width: 150px;
        height: 38px;
        position: relative;
        top: 9px;
      }
    }

    #app-nav {
      font-size: 12px;
      float: right;
      height: 50px;
      padding-right: 18px;
      background: url('/albatross/static/images/menu-bg.png') no-repeat scroll right bottom transparent;
      margin-top: 2px;

      ul {
        list-style: none;

        &.menu {
          height: 47px;
          padding-left: 13px;
          padding-top: 1px;
          background: url('/albatross/static/images/menu-bg.png') no-repeat scroll left top transparent;

          >li {
            margin-top: 7px;
          }
        }
      }
    }
  }
}

.wide-content{
  #nav-wrapper{
    #nav{
      width: auto;
      padding-top: 0.5em;
      padding-left: 1em;
    }
  }
}

#page-content-wrapper {
  &.short-header {
    padding-top: 50px;
    background-image: url('/albatross/static/images/page-header-bg-shortish.png');

    .no-tabs {
      .page-actions-top {
        float: none;
        text-align: right;
        margin-top: -25px;
      }
    }
  }
}
