@media print {
  #page-content-wrapper {
    padding-top: 0;
  }

  #sidebar-menu-notice-container {
    display: none;
  }

  #push {
    display: none;
  }

  .ui-tooltip {
    display: none !important;
  }
}