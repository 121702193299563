.sk-tab-list {
  li {
    margin-right: 3px !important;
    display: inline-block;

    a {
      display: block;
    }
  }
}

.sk-page-tabs {
  top: -5px;
  position: relative;
}

.sk-pagination {
  .prev-page, .pagination-pages, .next-page {
    display: inline-block;
  }

  .pagination-pages {
    margin: 0 5px;

    .active {
      font-weight: bold;
    }
  }
}

/* Select */
.sk-select {
  width: auto;
  font-size: 1em;
  outline: none;
  border-radius: 4px;
  border: 1px solid #aaa;
  background: #fff url('/albatross/static/images/jquery-ui/ui-bg_flat_75_ffffff_40x100.png') 50% 50% repeat-x;
  color: #222;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) inset;
  padding: .1em 0 .1em .5em;
  line-height: 1.4;

  &.sized {
    min-width: 140px;
  }
}

.component-crud-spinner {
  label {
    margin-bottom: 5px;
    color: #666666;
    font-style: italic;
    padding: 0px;
    font-size: 0.9em;
  }
}

.component-colour-picker {
  .input-addon {
    border-radius: 4px 0px 0px 4px;
    background: #E8E8E8;
    color: #AAAAAA;
    border: 1px solid #AAAAAA;
    float: left;
    width: 14px;
    height: 20px;
    font-weight: bold;
    text-align: center;
    padding: 0.2em 0.4em 0.1em 0.2em;
    border-right: none;
    margin-right: -3px;
  }

  .input-colour-picker {
    max-width: 6em;
    height: 20px;
    margin-right: 1em;
    text-transform: uppercase;
  }

  .colour-box {
    width: 10px;
    min-width: 4px;
    height: 15px;
    min-height: 10px;
    margin-top: -2px;
  }
}

.sk-loader {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -webkit-transition: all .25s ease-in-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  .message {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 400px;
    margin: auto;
    text-align: center;
    padding: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin-top: 10px;
  }

  .delayed-message {
    max-width: 400px;
    margin: auto;
    text-align: center;
    font-style: italic;
  }

  .spinner {
    background-image: url('/albatross/static/images/loading-quick.gif');
    width: 80px;
    height: 80px;
    margin: auto;
  }
}

.sk-modal {
  div.modal-footer {
    text-align: right;
  }
}

.sk-option-button {
  display: inline-block;
  vertical-align: top;

  .main-button {
    margin-right: 0;
    vertical-align: top;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
  }

  .select-wrapper {
    width: 16px;
    display: inline-block;

    button {
      border-left: none;
      -webkit-border-radius: 0 4px 4px 0;
      -moz-border-radius: 0 4px 4px 0;
      border-radius: 0 4px 4px 0;
      vertical-align: top;
      height: 25px;

      .ui-button-text {
        display: none;
      }

      .ui-icon {
        position: relative;
        margin: 0;
        top: 0;
        left: 0;
      }
    }
  }
}

.sk-toggle {
  position: relative;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  input {
    display: none;
  }
  label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #aaaaaa;

    .text {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.2s ease 0.1s;

      &:before, &:after {
        display: block;
        float: left;
        width: 50%;
        height: 20px;
        padding: 0;
        line-height: 20px;
        font-size: 10px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
      }

      &:before {
        content: "ON";
        padding-left: 10px;
        background-color: #bbf543;
        color: #000000;
      }
      &:after {
        content: "OFF";
        padding-right: 10px;
        background-color: #EEEEEE;
        color: #999999;
        text-align: right;
      }
    }

    .toggle {
      display: block;
      width: 20px;
      margin: 0;
      background: #FFFFFF;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 38px;
      border: 1px solid #aaaaaa;
      transition: all 0.2s ease 0.1s;
    }
  }

  input:checked + label .text {
    margin-left: 0;
  }

  input:checked + label .toggle {
    right: 0;
  }
}

.sk-ajax-error {
  width: 450px;
  margin: 15px auto!important;
  max-width: 450px;
  overflow-wrap: break-word;

  code {
    font-weight: bold;
  }

  p {
    margin: 0;
  }
}

.sk-crud-group {

  margin-bottom: 20px;

  h3 {
    border-bottom: 1px dotted #666;
    margin-bottom: 10px;
    position: relative;
    font-weight: bold;

    span {
      cursor: pointer;
      display: inline-block;
      vertical-align: bottom;
    }
  }
  h3:hover {
    color: #435F00;
  }

  &.sk-crud-opened {
    .outer, .crud-outer, .crud-is-hidden {
      display: none;
    }
  }


  &.sk-crud-closed {
    .inner, .crud-inner, .crud-is-shown {
      display: none;
    }

    h3 {
      border-bottom-color: transparent;
    }

    .outer, .crud-outer {
      font-size: .9em;
      font-style: italic;
      padding: 5px;
    }
  }

  .inner, .crud-inner {
    .crud-col-1{
      width: 49%;
      display: inline-block;
      vertical-align:text-top;
    }
    .crud-col-2 {
      width: 49%;
      display: inline-block;
      vertical-align:text-top;
    }
  }
}

.crud-grouped-fields {
  .sk-form-field-group {
    display: inline-block;
    margin-right: 10px;
  }
}

.sk-form-field-group {
  .form-field-description {
    margin-bottom: 5px;
    color: #666666;
    font-style: italic;
    padding: 0px;
    font-size: 0.9em;
  }

  &.with-errors {
    input, select {
      background: #FFDFDF;
      border-color: #9F0000;
    }

    .form-field-error {
      color: #9F0000;
      font-weight: bold;
    }
  }

  &.crud-group.crud-shown {
    .crud-inner { display: block; }
    .crud-outer { display: none; }
    .crud-title .crud-is-shown { display: inline-block; }
    .crud-title .crud-is-hidden { display: none; }

    .crud-title {
      border-bottom: 1px dotted #666;
      margin-bottom: 10px;
      position: relative;

      .ibex-crud-help {
        display: block;
        font-size: 11px;
        position: absolute;
        right: 0;
        bottom: 1px;
        cursor: help;
      }
    }
  }
  &.crud-group.crud-hidden {
    .crud-inner, .crud-title .crud-is-shown, .ibex-crud-help { display: none; }
    .crud-title .crud-is-hidden { display: inline-block; }
    .crud-outer { display: block; }
  }
  .crud-title-toggler:hover {
    color: #435F00;
  }
}

.crud-toggler {
  position: relative;
  top: 3px;
}
.crud-title-toggler {
  cursor: pointer;
}

.right-actions {
  padding-top: 10px;
  padding-bottom: 10px;
  float: right;
}
